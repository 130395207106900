import React from 'react'
import WithLocation from 'containers/WithLocation'
import SEO from 'components/SEO'

const RefLoading = props => {
	const { pathname } = props.location

	let type = 'index'
	if (pathname.indexOf('/dbs.html') !== -1) type = 'dbs'
	if (pathname.indexOf('/db-') !== -1) type = 'db'

	const meta = {
		index: {
			title: 'Заказать парсинг сайтов — «Парсик»',
			description:
				'Заказать парсинг сайтов, товаров. Получение и обновление данных в Excel формате. Разработка ТЗ. Поддержка 24/7',
		},
		dbs: {
			title: 'Базы компаний с email и телефонами в Excel — «Парсик»',
			description:
				'Базы компаний, организаций, фирм, предприятий в Excel по всей России. Скачать актуальную базу',
		},
		db: {
			title: 'База компаний с email и телефонами в Excel — «Парсик»',
			description:
				'Базы компаний, организаций, фирм, предприятий в Excel по всей России. Скачать актуальную базу',
		},
	}[type]

	return <SEO {...meta} />
}

export default WithLocation(RefLoading)
